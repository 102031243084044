import React from "react"

import { Header } from "../compositions"
import { Detail, H1, Link } from "../elements"
import { Frame } from "../layout"

function NotFoundPage() {
  return (
    <Frame>
      <Header className="z-10" />
      <div className="bg-blue-light mb-14">
        <div className="relative max-w-screen-lg mx-auto py-7 px-4">
          <H1 className="text-3xl font-bold mb-3">404 Hata</H1>
          <Detail className="mb-5">Aradığınız sayfaya ulaşılamadı</Detail>
          <Link
            to="/"
            type="secondary"
            className="xs:inline w-full xs:w-auto text-xs font-bold md:mb-3"
          >
            Ana Sayfaya Geri Dön
          </Link>
        </div>
      </div>
    </Frame>
  )
}

export default NotFoundPage
